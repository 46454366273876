// TODO(Kameron Ahler): consider moving functions out of this module
// TODO(Kameron Ahler): consolidate flags to dictionary

import { html } from 'lit';
import { configStateStore } from '../widget/common/helpers/stateManager';

export const hasFlag = (flag: string) =>
  !!configStateStore.getState().flags?.includes(flag);

export const getDefaultTerms = () => {
  const configManager = configStateStore.getState();
  const disclaimerObj = configManager.disclaimer;

  if (!disclaimerObj) {
    throw new Error(
      'Disclaimer data is missing from global state. Make sure you saved these values in the ConfigManager',
    );
  }

  const termsOfServiceText = disclaimerObj.terms_of_service.text;
  const privacyPolicyText = disclaimerObj.privacy_policy.text;

  const termsOfServiceLink = disclaimerObj.terms_of_service.url;
  const privacyPolicyLink = disclaimerObj.privacy_policy.url;
  const formattedDisclaimerText = disclaimerObj.text.split(/{.+?}/);

  return html`
    <div data-cy="disclaimer-text">
      ${formattedDisclaimerText[0]}
      <a
        aria-label=${`${termsOfServiceText} (view in new window)`}
        href="${termsOfServiceLink}"
        target="_blank"
        rel="noopener"
        data-popup-engagement="true"
        data-cy="terms-of-service-link"
      >
        ${termsOfServiceText}
      </a>
      ${formattedDisclaimerText[1]}
      <a
        aria-label=${`${privacyPolicyText} (view in new window)`}
        href="${privacyPolicyLink}"
        target="_blank"
        rel="noopener"
        data-popup-engagement="true"
        data-cy="privacy-policy-link"
      >
        ${privacyPolicyText} </a
      >${formattedDisclaimerText[2]}
    </div>
  `;
};

const getStaticTerms = (hostname: string) => {
  const configManager = configStateStore.getState();

  let tosLink = `https://${hostname}/policies/terms-of-service.html`;
  let privacyLink = `https://${hostname}/policies/privacy-policy.html`;

  if (
    configManager.shopShop &&
    configManager.flags?.split(',').includes('dynamic_terms')
  ) {
    tosLink = `https://terms.pscr.pt/legal/shop/${configManager.shopShop}/terms_of_service`;
    privacyLink = `https://terms.pscr.pt/legal/shop/${configManager.shopShop}/privacy_policy`;
  }

  return html`*I agree to receive recurring automated marketing text messages
    (e.g. cart reminders) at the phone number provided. Consent is not a
    condition to purchase. Msg & data rates may apply. Msg frequency varies.
    Reply HELP for help and STOP to cancel. View our
    <a
      style="color:inherit !important; text-decoration: underline;"
      href="${tosLink}"
      target="_blank"
      rel="noopener"
      data-popup-engagement="true"
    >
      Terms of Service
    </a>
    and
    <a
      style="color:inherit !important; text-decoration: underline;"
      href="${privacyLink}"
      target="_blank"
      rel="noopener"
      data-popup-engagement="true"
    >
      Privacy Policy.
    </a>`;
};

export const DEFAULT_EXCLUDED_HOSTS = ['getshogun.com'];

export const DEFAULT_OPT_IN_LABEL = 'Send me special offers through text*';

export const POPUP_CONTAINER_ID = 'ps__container';
export const POPUP_IMPRESSION_MESSAGE_TYPE = 'POPUP_IMPRESSION';
export const POPUP_SUBMIT_MESSAGE_TYPE = 'POPUP_FORM_SUBMIT';
export const POPUP_CLOSE_MESSAGE_TYPE = 'POPUP_CLOSE';
export const SUBSCRIBER_CREATED_MESSAGE_TYPE = 'SUBSCRIBER_CREATED';
export const POPUP_WIDGET_CONTAINER_SELECTOR = 'ps__widget_container';
export const PARTIAL_POPUP_SIZE = 'Partial';
export const FULL_POPUP_SIZE = 'Full';
export const PARTIAL_POPUP_HEIGHT = '100vh';
export const PARTIAL_POPUP_WIDTH = '100vw';
export const SESSION_COOKIE_NAME = '_ps_session';
export const TWOTAP_ID_COOKIE_NAME = '_ps_twotap_id';
export const SUBSCRIBER_ID_COOKIE_NAME = 'ps_id';
export const SUBSCRIBER_PREVIOUS_SESSION_COOKIE_NAME =
  'ps_subscriber_previous_session';
export const POPUP_COOKIE_NAME = '_ps_pop';
export const POPUP_STATUS_COOKIE_NAME_PREFIX = `${POPUP_COOKIE_NAME}_`;
export const SPLIT_TEST_STATUS_COOKIE_NAME_PREFIX = `ps_ab_test_`;
export const SPLIT_TEST_QUERY_STRING_PARAMETER = 'splitTests';
export const POPUP_SOFT_CLOSE_STATE = 'r';
export const POPUP_HARD_CLOSE_STATE = 'a';
export const OPT_IN_CHECKED_COOKIE_NAME = '_ps_opt_in_checked';
export const TOKEN_COOKIE_NAME = 'ps_aid';
export const IMPRESSION_EVENT_DESKTOP = 'popup_impression_desktop';
export const CLICK_EVENT_DESKTOP = 'popup_click_desktop';
export const CLICK_EVENT_EMAIL_DESKTOP = 'popup_email_click_desktop';

export const IMPRESSION_EVENT_MOBILE = 'popup_impression';
export const CLICK_EVENT_MOBILE = 'popup_click';
export const CLICK_EVENT_EMAIL_MOBILE = 'popup_email_click';
export const PAGE_VIEW_EVENT = 'page_view';
export const ADD_TO_CART_EVENT = 'add_to_cart';
export const SUCCESS_SCREEN = 'success';
export const BUNDLED_STYLES_SELECTOR = 'ps__bundled_styles_link';
export const POPUP_DESKTOP_WIDGET_CONTAINER = 'ps-desktop-widget__container';
export const POPUP_MOBILE_WIDGET_CONTAINER =
  '#ps-mobile-widget-partial__container, #ps-mobile-widget-overlay__container';

export const BIS_ROOT_ID = 'ps__bis_container_root';
export const BIS_MOBILE_POPUP_CONTAINER_ID =
  'ps-bis-widget-mobile-modal__container';
export const BIS_DESKTOP_STYLES_SELECTOR = 'ps__bis_desktop_styles';
export const BIS_BASE_STYLES_SELECTOR = 'ps__bis_base_styles';
export const BIS_MOBILE_STYLES_SELECTOR = 'ps__bis_mobile_styles';
export const BIS_PHONE_INPUT_SELECTOR = 'ps-bis-widget-modal__phone-input';
export const BIS_DEFAULT_SIDETOAST_MESSAGE = 'Text me when available';
export const BIS_DEFAULT_POPUP_HEADLINE =
  'Receive a notification when this item is restocked';
export const BIS_DEFAULT_SUBSCRIBE_BUTTON_TEXT = 'Notify me';
export const BIS_DEFAULT_LINK_TEXT =
  'Notify me when this item is back in stock!';
export const BIS_FADE_IN_CLASS = 'ps-bis-fade-in';
export const BIS_FADE_OUT_CLASS = 'ps-bis-fade-out';
export const BIS_POPUP_RENDERED_MESSAGE = 'BIS_POPUP_RENDERED';
export const BIS_VARIANT_COOKIE_ID_PREFIX = '_ps_back_in_stock_';
export const BIS_TARGET_ID = 'ps__bis_target_container';
export const BIS_ADDED_CLASS = 'ps-bis-custom-container-added';
export const POPUP_EXIT_INTENT = 'ps-popup-exit-intent';

export const PS_POPUP_IFRAME_ID = 'ps__widget';

export const CANARY_MULTI_POPUP = 'sdk_multi_popup_canary';

export const LOG_HEADLESS_SHOPS_FLAG = 'log_headless_shops';
export const LISTRAK_INTEGRATION_FLAG = 'listrak_popup_integration';
export const POPUP_NEW_COMPLIANCE_TEXT = 'popup_new_compliance_text';
export const POPUP_SUBSCRIBER_TARGETING_FLAG = 'popup_subscriber_targeting';
export const POPUP_BLOCK_EDITOR_FLAG = 'popup_block_editor';

export const CASHBACK_CODE_EVENT_NAME = 'CASHBACK_CODE';
export const CASHBACK_CODE_APPLIED_NAME = 'CASHBACK_CODE_APPLIED';
export const SHOPIFY_DISCOUNT_CODE_COOKIE_NAME = 'discount_code';

export const DEFAULT_TERMS = (hostname: string) => {
  const configManager = configStateStore.getState();
  const hasUpdatedComplianceText = configManager.flags
    ?.split(',')
    .includes(POPUP_NEW_COMPLIANCE_TEXT);

  if (hasUpdatedComplianceText) {
    return getDefaultTerms();
  }

  return getStaticTerms(hostname);
};

export const POPUP_EVENT_TYPES = {
  ENGAGEMENT: 'engagement',
  IMPRESSION: 'impression',
  SUBMIT_EMAIL: 'submit_email',
};

export const POPUP_TYPES = {
  DESKTOP: 'DESKTOP',
  MOBILE: 'MOBILE',
};

export const POPUP_TRIGGER_TYPES = {
  CUSTOM: 'Custom',
  DELAY: 'Delay',
  EXIT: 'Exit',
};

// Our checkout UI extension can't set cookies directly, but it can use localStorage
// These keys will be set in localStorage by the extension so we can read them back out
// and set the appropriate cookies
export const SHOPIFY_EXTENSION_LOCAL_STORAGE_KEY = 'extensionStorage';
export const SUBSCRIBER_ID_LOCAL_STORAGE_KEY = 'ps_subscriber_id';
export const SUBSCRIBER_TOKEN_LOCAL_STORAGE_KEY = 'ps_token';

// If a user opts out of data tracking we can still track events we consider essential
export const ESSENTIAL_TRACKING_EVENTS = ['customer_submitted', 'purchase'];

export const PHONE_COLLECTION_ATTRIBUTE = 'phone';
export const EMAIL_COLLECTION_ATTRIBUTE = 'email';

export const FONT_NAME = {
  Arial: 'Arial',
  TimesNewRoman: 'Times New Roman',
} as const;

export const MESSAGING_URL_PREFIX = 'sms:';
export const BODY_PARAM_SEPARATOR = '&body=';

export const SUBSCRIBER_COHORTS = {
  NEW_SMS_USERS: 'NEW_SMS_USERS',
  EVERYONE: 'EVERYONE',
  EXISTING_SMS_SUBSCRIBERS: 'EXISTING_SMS_SUBSCRIBERS',
} as const;

export const V2_OPT_IN_SOURCES = {
  DESKTOP: 'Desktop Popup',
  MOBILE: 'Mobile Popup',
  THIRD_PARTY: '3rd Party Onsite Opt-in',
} as const;

export const BLOCK_OPT_IN_SOURCES = {
  DESKTOP: 'DESKTOP_BLOCK_POPUP',
  MOBILE: 'MOBILE_BLOCK_POPUP',
} as const;
